// Packages
import React from "react";
import { InputItem, Button, Toast, Icon } from "antd-mobile";
// Styles
import "./SignIn.scss";

class SignIn extends React.Component {
    state = {
        redirecting: false,
        accountId: null,
    };

    handleChange(event) {
        this.setState({ accountId: event });
    }

    getAccountIdFromUrl() {
        return this.props.match.params.id;
    }

    getRegisterUrl() {
        return process.env.REACT_APP_REDIRECT_URL_BASE;
    }

    setRedirecting(redirect) {
        this.setState({ redirecting: redirect });
    }

    redirectToRegister(accountId) {
        window.location.href = this.getRegisterUrl() + accountId;
    }

    componentDidMount() {
        if (isNaN(this.getAccountIdFromUrl())) {
            this.setRedirecting(false);
        } else {
            this.setRedirecting(true);
            this.redirectToRegister(this.getAccountIdFromUrl());
        }
    }

    render() {
        return (
            <div className="auth-page sign-in-page">
                <div className="auth-logo-wrapper">
                    <img
                        src="/assets/logo-white.png"
                        alt="GF Crew"
                        className="auth-logo"
                    />
                </div>

                <div className="notes">
                    {this.state.redirecting === true && (
                        <p>
                            Redircting to registration account (
                            {this.getRegisterUrl()})
                        </p>
                    )}
                    {this.state.redirecting === false && (
                        <div>
                            <p>
                                Ooops! This is not the page you wanted.
                                <br />
                                Please contact your photographer to get the
                                correct link for you.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SignIn;
