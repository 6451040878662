import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";
import RootStore from "./stores/root";
import enUS from "antd-mobile/lib/locale-provider/en_US";
import { LocaleProvider } from "antd-mobile";

ReactDOM.render(
    <Provider store={new RootStore()}>
        <LocaleProvider locale={enUS}>
            <App />
        </LocaleProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
