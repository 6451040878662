// Packages
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

class AppLayout extends React.Component {
    getToken = () => {
        return localStorage.getItem("capturelife-token");
    };

    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(routeProps) =>
                    !this.getToken() ? (
                        <Redirect to="/register" />
                    ) : (
                        <div className="app-layout"></div>
                    )
                }
            />
        );
    }
}

export default withRouter(AppLayout);
