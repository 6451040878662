// Packages
import React from "react";
import { inject, observer } from "mobx-react";
// Third Party Components
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// App Components
import AppLayout from "./layouts/AppLayout/AppLayout";
import SignIn from "./pages/SignIn/SignIn";

// Styles & Images
import "./App.scss";

const App = inject("store")(
    observer(
        class App extends React.Component {
            render() {
                return (
                    <Router>
                        <Switch>
                            <AppLayout path="/" component={SignIn} exact />
                            <Route path="/:id" component={SignIn} exact />
                        </Switch>
                    </Router>
                );
            }
        }
    )
);

export default App;
